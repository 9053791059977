<template>
  <form
    class="form-cms recipe-ingredient"
    @submit.prevent="updateRecipeIngredient({ unit, amount })"
    :class="[index !== 0 ? 'mt-2' : '']"
  >
    <div
      class="form-control mt-1"
      v-if="idRecipeIngredient === ingredientEdit.idRecipeIngredient"
    >
      <label for="name-ingredient-recipe">Ingrediente:</label>
      <v-select
        :options="ingredients"
        label="nombre"
        v-model="currentEditIngredient"
        placeholder="Nombre de ingrediente"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró el ingrediente: {{ search }}
          </template>
        </template>
      </v-select>
    </div>
    <div
      class="form-control"
      v-if="idRecipeIngredient !== ingredientEdit.idRecipeIngredient"
    >
      <label for="name-ingredient-recipe">Ingrediente:</label>
      <input
        type="text"
        name="name-ingredient-recipe"
        placeholder="Nombre de ingrediente"
        v-model="name"
        :disabled="idRecipeIngredient !== ingredientEdit.idRecipeIngredient"
      />
    </div>

    <div class="fields-flex">
      <div class="form-control">
        <label for="unit-ingredient-recipe">Unidad:</label>
        <input
          v-if="idRecipeIngredient !== ingredientEdit.idRecipeIngredient"
          type="text"
          name="unit-ingredient-recipe"
          placeholder="Unidad de medida"
          v-model="unit"
          :disabled="idRecipeIngredient !== ingredientEdit.idRecipeIngredient"
        />

        <select
          name="unit-ingredient-recipe"
          v-model="unit"
          ref="currentEditUnit"
          v-if="
            idRecipeIngredient === ingredientEdit.idRecipeIngredient &&
              !isLoadingEditUnits &&
              ingredientEdit.unitList.length === 0
          "
        >
          <option
            v-for="(unit, index) in unitList"
            :value="unit.medidaAmigable"
            :key="index"
          >
            {{ unit.medidaAmigable }}
          </option>
          <option :value="unit" disabled>Seleccione una unidad</option>
        </select>

        <select
          name="unit-ingredient-recipe"
          v-model="unit"
          ref="currentEditUnit"
          v-if="
            idRecipeIngredient === ingredientEdit.idRecipeIngredient &&
              !isLoadingEditUnits &&
              ingredientEdit.unitList.length > 0
          "
        >
          <option
            v-for="(unit, index) in ingredientEdit.unitList"
            :value="unit.medidaAmigable"
            :key="index"
          >
            {{ unit.medidaAmigable }}
          </option>
          <option :value="unit" disabled>Seleccione una unidad</option>
        </select>

        <p
          class="text-secondary"
          v-if="
            idRecipeIngredient === ingredientEdit.idRecipeIngredient &&
              isLoadingEditUnits
          "
        >
          Actualizando unidades..
        </p>
      </div>
      <div class="form-control">
        <label for="cant-ingredient-recipe">Cantidad:</label>
        <input
          type="number"
          name="cant-ingredient-recipe"
          placeholder="Cantidad"
          step="0.01"
          v-model="amount"
          :disabled="idRecipeIngredient !== ingredientEdit.idRecipeIngredient"
        />
      </div>
    </div>

    <Message
      v-if="editIngredientShowMsg"
      :message="editIngredientMsg"
      :messageType="editIngredientMsgStatus"
    />

    <div class="buttons gap-0-5">
      <button
        class="btn btn-primary btn-medium"
        type="button"
        @click="
          editRecipeIngredient({
            idIngredient,
            idRecipeIngredient,
            unitList,
            name,
          })
        "
        v-if="idRecipeIngredient !== ingredientEdit.idRecipeIngredient"
      >
        <i class="fas fa-pen-alt"></i> Editar
      </button>

      <button
        class="btn btn-outline-primary btn-medium"
        type="submit"
        v-if="
          idRecipeIngredient === ingredientEdit.idRecipeIngredient &&
            !isLoadingEditIngredient
        "
        @click="cancelEdition"
      >
        Cancelar edición
      </button>

      <button
        class="btn btn-primary btn-medium"
        type="submit"
        v-if="idRecipeIngredient === ingredientEdit.idRecipeIngredient"
        :disabled="isLoadingEditIngredient"
      >
        <template v-if="!isLoadingEditIngredient">
          <i class="fas fa-pen-alt"></i> Actualizar
        </template>
        <template v-else>
          Actualizando ...
        </template>
      </button>

      <button
        class="btn btn-secondary btn-medium"
        type="button"
        :disabled="isLoadingDeleteIngredient"
        @click="deleteRecipeIngredient(idRecipeIngredient)"
      >
        <template v-if="!isLoadingDeleteIngredient">
          <i class="far fa-trash-alt"></i> Eliminar
        </template>
        <template v-else>
          Eliminando ...
        </template>
      </button>
    </div>
  </form>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import useRecipeIngredients from '@/modules/cms/composables/recipes/useRecipeIngredients';

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    idRecipeIngredient: {
      type: Number,
      required: true,
    },
    idIngredient: {
      type: Number,
      required: true,
    },
    amount: {
      type: Number,
    },
    name: {
      type: String,
    },
    unitList: {
      type: Array,
    },
    unit: {
      type: String,
    },
  },

  components: {
    'v-select': vSelect,
    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },

  setup() {
    return {
      ...useRecipeIngredients(),
    };
  },
};
</script>
